"use client"

import { useEffect } from "react"
import { usePathname } from "next/navigation"

import mixpanel from "@lib/util/events/mixpanel"

export default function MixpanelTrack() {
  const pathname = usePathname()

  useEffect(() => {
    console.log("Tracking pageview for", pathname)
    mixpanel.track("Page View", { page: pathname })

    return () => {}
  }, [pathname])

  return null
}
