//src/lib/mixpanel.ts
import { InitConfig, Mixpanel as IMixpanelServer } from "mixpanel"
import { Config, Mixpanel as IMixpanelClient } from "mixpanel-browser"

const test = process.env.NEXT_PUBLIC_MIXPANEL_TEST_TOKEN
const live = process.env.NEXT_PUBLIC_MIXPANEL_LIVE_TOKEN
let token = ""
let options: Partial<Config | InitConfig> = {}
let mixpanel

if (process.env.NODE_ENV === "development") {
  token = test as string

  options = {
    debug: true,
    record_sessions_percent: 5,
    record_block_selector: "",
    record_mask_text_selector: "",
  }
} else {
  token = live as string
  options = {
    record_sessions_percent: 30,
    record_block_selector: "",
    record_mask_text_selector: "",
  }
}

if (typeof window === "undefined") {
  mixpanel = require("mixpanel")
  mixpanel.init(token as string, { ...options })
} else {
  mixpanel = require("mixpanel-browser").default
  mixpanel.init(token as string, {
    ...options,
    persistence: "localStorage",
  })
}

export default mixpanel as IMixpanelClient & IMixpanelServer
