"use client"

import { usePathname } from "next/navigation"
import React, { useEffect } from "react"

interface TidioChatProviderProps {
  children?: React.ReactNode
}

export const TidioChatProvider = ({ children }: TidioChatProviderProps) => {
  const pathname = usePathname()

  useEffect(() => {
    const disabledPages = ["/designer", "/kassa", "/kundvagn"]
    if (typeof window !== "undefined") {
      const element = document.getElementById("tidio-chat-iframe")

      if (element) {
        if (disabledPages.includes(pathname)) {
          element.style.display = "none"
        } else {
          element.style.display = "block"
        }
      }
    }
  }, [pathname])

  return <>{children}</>
}
