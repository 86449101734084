import { StoreCartsRes } from "@medusajs/medusa"
import { useMutation, UseMutationOptions } from "@tanstack/react-query"
import { useMedusa } from "medusa-react"

export interface StorePostCartReqWithCustomItems {
  cart_id: string
  variant_id: string
  quantity: number
  customizations: {
    name: string
    value: string
    price: number
  }[]
}

export const useAddCustomItemToCart = (
  options?: UseMutationOptions<
    StoreCartsRes["cart"],
    Error,
    StorePostCartReqWithCustomItems
  >
) => {
  const { client } = useMedusa()
  const mutation = useMutation(
    async (data: StorePostCartReqWithCustomItems) => {
      const res: StoreCartsRes = await client.client.request(
        `POST`,
        `/store/line-items/custom`,
        data
      )
      return res.cart
    }
  )

  return mutation
}
